.RootAdvertInfo {
  .advertInfoRow {
    .advertInfoCol {
      .headerWrap {
        display: flex;
        justify-content: space-between;
      }
      .buttonsWrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .btn {
          &:nth-child(1) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
