.filterDatesCol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vw;
  margin-bottom: 1vw;

  & > .InputFromTo,
  & > .InputFromTo .DayPickerInput {
    margin-right: 1vw;

    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 2vw;

      &,
      input {
        width: 100%;
      }
    }
  }

  .filterDatesWrap {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 12px;
    }
    &:nth-child(2) {
      margin-left: 12px;
      margin-right: 12px;
    }
    &:nth-child(3) {
      margin-left: 12px;
    }

    @media (max-width: 991px) {
      &.filterDatesDescription {
        display: none;
      }
    }
    // @media (max-width: 600px) {
    //   &.filterDatesDescription {
    //     display: none;
    //   }
    // }
    // @media (max-width: 600px) {
    //   &.filterDatesDescription {
    //     display: none;
    //   }
    // }
    // @media (max-width: 500px) {
    //   &.filterDatesDescription {
    //     display: flex;
    //   }
    // }
  }
  .DayPicker-Day {
    border-radius: 0;
  }
  .DayPicker-Day--selected {
    background-color: theme-color('primary') !important;

    &:hover {
      background-color: theme-color('secondary') !important;
    }
  }

  @media (max-width: 991px) {
    .filterDatesWrap {
      margin: 0 !important;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 2vw !important;
      }
    }

    .InputFromTo {
      text-align: center;
    }
  }
  @media (max-width: 422px) {
    .dateFilterDash {
    }
  }
}
