.RootOffers {
  .tableFooter {
    .paginationWrap {
      ul {
        margin-bottom: 0;
        margin-left: 1vw;
        margin-right: 1vw;
        li {
          list-style: none;
        }
      }
    }
  }
}
