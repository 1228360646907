.RootNumbers {
  .react-dropdown-select {
    min-width: 10vw;
    max-width: 200px;

    @media (max-width: 991px) {
      min-width: inherit;
      max-width: inherit;
    }
  }
  .react-dropdown-select-dropdown {
    min-width: 100%;
  }
  .numbersCard {
    .filterDatesCol {
      justify-content: start;
      margin-bottom: 0;
      padding: 0;
    }
    .cardFiltersHeader {
      align-items: flex-end;
      margin-bottom: 1vw;

      .filteringWrap {
        .btnClear {
          margin-right: 1vw;
        }
        .filterSelect {
          margin-right: 1vw;
          &:nth-last-child(1) {
            margin-right: 0;
          }
          @media (max-width: 991px) {
            margin-right: 0;
          }
        }
        @media (max-width: 991px) {
          .InputFromTo {
            text-align: left;
          }
          .filterSelect {
            &:nth-last-child(1) {
              margin-top: 2vw;
            }
          }
        }
      }
      @media (max-width: 991px) {
        margin-bottom: 2vw;
        display: block;
      }
    }
  }
  @media (max-width: 991px) {
    .expandOptions {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 2vw 0 1vw 0;

      i {
        margin-right: 1vw;
      }
    }
    .searchBoxWrap {
      display: flex;

      .searchBoxFilter {
        margin-bottom: 0;
      }

      .csvExportLink {
        margin-left: 3vw;
      }
    }
  }
}
