//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.btn-light {
  border: 1px solid darken($light, 2%);
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.btn-outline-light {
  color: $gray-900;
}

.btnGroupWrap {
  .btnCancel {
    margin-right: 1.5vw;
    @media (max-width: 991px) {
      margin-right: 0;
    }
  }
  .btnApply {
  }

  @media (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
