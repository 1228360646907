//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.tableStyle {
  .tableTh {
    cursor: pointer;
    &:hover {
      background-color: $gray-300;
    }
  }
  tr {
    &:hover,
    &:focus {
      background-color: $gray-300;
    }

    &.trFeatured {
      background-color: $sidebar-dark-bg;

      * {
        color: #fff;
      }
    }
  }
  @media (max-width: 991px) {
    width: 100%;

    tr td {
      width: 1%;
      white-space: nowrap;
    }
  }
}
