.flexAlignedCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.badgeDot {
  width: 0.375rem;
  height: 0.375rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 0.374rem;

  padding: 0;

  &.badgeDotRight {
    margin-left: 0.374rem;
    margin-right: 0;
  }
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexVerticalStart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.cardBodyHeader {
  @media (max-width: 991px) {
    align-items: flex-start;

    flex-wrap: wrap;

    & > .btn {
      margin-bottom: 2vw;
    }
    & > .btn,
    & > .searchBoxWrap,
    & > .filteringWrap {
      width: 100%;
    }
  }
}

.verticalMargin {
  margin: 1vw 0;
  @media (max-width: 991px) {
    margin: 2vw 0;
  }
}

.searchBoxWrap,
.filteringWrap {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 0;

  &.filteringWrap {
    align-items: flex-end;
    .form-group {
      margin-bottom: 0;
    }
  }

  .moreOptions {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    i {
      padding: 0 3vw;
      font-size: 3vw;

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sliceNumber {
    margin-left: 1vw;

    @media (max-width: 991px) {
      margin-left: 0;
      margin-top: 1vw;
    }
  }
  #example-search-input {
    @media (max-width: 991px) {
      margin-bottom: 1vw;
    }
  }

  @media (max-width: 991px) {
    display: block;
  }
}

// MARGINS
.noMargin {
  margin: 0 !important;
}
.noPadding {
  padding: 0 !important;
}

// ALIGNS
.verticalMiddle {
  vertical-align: middle;
}

.linkBackgroundFullSize {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
