.RootInfoSections {
  .infoSectionsRow {
    .infoSectionsCol {
      .headerWrap {
        display: flex;
        justify-content: space-between;

        .statusDiv {
          margin-right: 2vw;
        }
      }
      .buttonsWrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .btn {
          &:nth-child(1) {
            margin-right: 1rem;
          }
        }
      }

      .react-dropdown-select[disabled] {
        @extend .form-control, [readOnly];
        opacity: 1;
      }
    }
    .infoLabels {
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;

      .tooltipIcon {
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }
  @media (max-width: 991px) {
    .expandOptions {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 2vw 0 1vw 0;

      i {
        margin-right: 1vw;
      }
    }
  }
}
