.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;

  margin: 3vw 0 2vw 0;
  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid theme-color('primary');
    border-color: theme-color('primary') transparent theme-color('primary')
      transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
