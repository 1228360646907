.afTableRow {
  cursor: pointer;
}

// table cell status
.redCell {
  background-color: rgba(255, 0, 0, 0.5);
  color: #fff;
}
.orangeCell {
  background-color: rgba(255, 238, 0, 0.5);
  // color: #fff;
}
.greenCell {
  //   background-color: rgba(0, 128, 0, 0.5);
  //   color: #fff;
}
